import React, { useState } from "react";
import { Fragment } from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdDashboardCustomize } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { TbCertificate } from "react-icons/tb";
import { setHeaderName } from "./../../store/slices/utilSlice";
import { useDispatch } from "react-redux";
import { useGetCertificatesQuery } from "../../store/slices/apis/masterApi";
import { useGetAppliedCertificatesQuery } from "../../store/slices/apis/applicationApi";
import Stepper from "../../Components/Utils/Stepper";

export default function StudentDashboard({ currentStep }) {
  const submitStepper = () => {
    console.log("submitted");
  };

  const dispatch = useDispatch();
  const {
    data: certificates = [],
    isLoading: isLoadingGetCertificates,
    isSuccess: isSuccessGetCertificates,
    isError: isErrorGetCertificates,
  } = useGetCertificatesQuery();
  const {
    data: appliedCertificates = [],
    isLoading: isLoadingGetAppliedCertificate,
    isSuccess: isSuccessGetAppliedCertificates,
    isError: isErrorGetAppliedCertificates,
  } = useGetAppliedCertificatesQuery();

  return (
    <>
      <div className='relative space-y-4'>
        <div className='flex flex-col justify-between gap-4 p-4 bg-gray-100 shadow-2xl sm:flex-row'>
          <h1 className='flex items-center gap-2 text-xl font-medium'>
            <MdDashboardCustomize className='mt-1 text-blue-600' /> Dashboard
          </h1>
          {/* <div
            class='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
            role='alert'
          >
            <strong class='font-bold'>Notice:</strong>
            <span class='block sm:inline'>
              We are currently experiencing issues with file uploads. Please try
              again later.
            </span>
          </div> */}
          <Link
            to='/applied-certificates'
            className='px-4 py-2 font-medium text-white transition duration-300 ease-in-out transform bg-blue-500 rounded-full hover:bg-blue-700 hover:-translate-y-1 hover:scale-110'
          >
            View Applied Certificate
          </Link>
        </div>

        <div className='grid grid-cols-12 gap-4 shadow-2xl'>
          <div className='col-span-12 sm:col-span-6'>
            <div className='flex flex-col'>
              <div className='grid grid-cols-1 gap-4 mt-4 ml-4 mr-4'>
                {certificates &&
                  certificates?.map((certificate) => (
                    <div
                      onClick={() => {
                        dispatch(setHeaderName(certificate.name));
                      }}
                      key={certificate.id}
                      className='flex items-center p-4 transition duration-300 ease-in-out transform bg-white shadow-lg rounded-xl hover:shadow-xl hover:-translate-y-1 hover:scale-105'
                    >
                      <div className='flex items-center justify-center w-12 h-12 text-2xl text-blue-500 border border-blue-100 rounded-full bg-blue-50'>
                        <TbCertificate />
                      </div>

                      <div className='flex-grow ml-4'>
                        <h2 className='font-semibold'>{certificate.name}</h2>
                      </div>

                      <div className='flex gap-2'>
                        <Link
                          to={`/certificates/create/${certificate.id}`}
                          // to='#'
                          className='flex items-center px-4 py-2 text-sm font-semibold text-white transition duration-300 ease-in-out bg-indigo-500 rounded-full hover:bg-blue-400 hover:-translate-y-1 hover:scale-105'
                        >
                          Apply
                          <span className='ml-2'>
                            <BsArrowRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className='col-span-12 sm:col-span-6'>
            <div className='items-center p-4 mt-4 ml-4 mr-4 bg-white shadow-lg rounded-xl'>
              <div className='px-6 py-3 text-black border-b-2 border-neutral-100'>
                <h1 className='font-semibold'>Applied Certificates</h1>
              </div>
              <div>
                {appliedCertificates?.map((certificate) => {
                  return (
                    <div key={certificate.id}>
                      <Stepper data={certificate} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
